import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Helmet from "react-helmet";
import Layout from 'components/Layout';
import BlogPost from 'components/pages/BlogPost';
import { Image } from 'components/pages/BlogPost/styled';
import { ImgGatsbyBackground } from 'components/wrappers';
import Cargo2ImageSource from 'images/Cargo2.jpg';
import CargoThumbImageSource from 'images/featured-image/Cargo2.jpg';
import UsChinaImageSource from 'images/US-China.jpg';
import styled, { css } from 'styled-components';
import { blogPosts } from 'common/blogPostsFeatured';
import BlogBaseCard from 'components/blog/BaseCard';
import OptinBlog from 'components/pages/OptinBlog';
import BlogSidebar from 'components/blog/BlogSidebar';
import { PostsWrapper } from 'components/pages/Blog/styled';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';
import FacebookDefaultIcon from 'images/social-share-icons/facebook-white.png';
import TwitterDefaultIcon from 'images/social-share-icons/twitter-white.png';
import LinkedinDefaultIcon from 'images/social-share-icons/linkedin-white.png';
import BlogOptin from 'components/blog/BlogOptin';
import {Clear, BlogRow, ShareButton, ReadNow, DateText, TitleText, BottomShare, SectionFull, OptinSection} from 'components/blog/SingleBlogStyle';

interface OwnProps {
  location: {
    href: string;
  };
}

const BlogArticle = ({ location }: OwnProps) => {
  const data = useStaticQuery(graphql`
    query ImgBlog2Query {
      file(relativePath: { eq: "US-China.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const BackgroundImage = () => <ImgGatsbyBackground fluid={data.file.childImageSharp.fluid} alt="shipkoo ecommerce shipping solutions usa china tradewar best opportunity"/>;
  const { href } = location;
  const intl = useIntl()
  return (
    <Layout>
      <Helmet 
        // title="US-China Trade War - An Opportunity for Shipkoo"
        title={intl.formatMessage({ id: "blog-post-meta-title-us-china-trade-war" })}
        htmlAttributes={{
          lang: 'en',
        }}
         meta={
          [
            {
              name: `description`,
              content: "Shipkoo sees an opportunity despite of the U.S.-China trade war. Find out how they do it.",
            },
            {
              property: `og:title`,
              // content: "US-China Trade War - An Opportunity for Shipkoo",
              content: intl.formatMessage({ id: "blog-post-meta-title-us-china-trade-war" }),
            },
            {
              property: `og:description`,
              content: "Shipkoo sees an opportunity despite of the U.S.-China trade war. Find out how they do it.",
            },
            {
              property: `og:type`,
              content: `website`,
            },
            {
              property: `og:image`,
              content: UsChinaImageSource,
            },
            {
              name: `twitter:card`,
              content: `summary`,
            },
          ] as JSX.IntrinsicElements['meta'][]
        }
      />
      <BlogPost
        location={location}
        id="china-trade-war"
        title="Why the US-China Trade War is Actually an Opportunity for Shipkoo"
        date="2019-11-29"
        author="Joe Zhang"
        Background={BackgroundImage}
      >
        <BlogRow>
          <BlogSidebar/>
          <div className="column-half last">
            <p>
              The ongoing trade wars between China and the United States has begun affecting the
              economies of all the involved countries that have business relationship. The shipping and
              logistics industry are among the core avenues where the greatest impacts are felt. In
              2019, there has been a drop in imports and exports by nearly 6.5% in China and 10% in USA
              respectively.
            </p>

            <p>
              The emerging issues in trade war between China and USA is likely to continue in the coming
              years, hence causing a potentially reshaping of the logistics and shipping industry. For
              example, U.S importers have begun switching their production from China to other countries
              such as Malaysia, Vietnam, India, and the Middle East. This trend my not only exist for
              some years, but it will also accelerate in the coming years. What does this tell you?
              Basically, this means that China has to establish its own mechanisms that can sustain
              logistics operations. Besides, this implies that there would be a really huge imbalance
              between supply and demand of the logistics services due to the delinked services that were
              initially provided by the U.S logistic companies.
            </p>

            <p>
              This is the point where Shipkoo comes in to play to salvage the dire situation that’s
              crawling in. Shipkoo basically provides logistic services that is established through
              proprietary technology as well as service culture that is driven by deep understanding on
              different challenges in this industry.
            </p>

            <h5>Diversification of import sources</h5>

            <p>
              Besides, since there will be much diversification of import sources, Shipkoo will be
              advantageous handling importers who will be in the shifting course either in processing or
              manufacturing. For instance, there has already been repeat cases of such instances where
              the U.S importers have begun switching their production operations from China to other
              countries. Consequently, this has translated to small orders with increased number of
              shipments to more locations. Moreover, Section 321 of the Tariff Act allows merchandise
              shipments below US$800 to enter the US without paying duties. Shipkoo helps customer to
              claim that exemption when it sends parcel shipments from their supplier. Therefore, with
              expertise on handling small orders, this provides the opportunity Shipkoo to become more
              competitive.
            </p>

            <Image src={Cargo2ImageSource} alt="shipkoo ecommerce best international cargo shipping solution" />
            <h5>Increased Consolidation in The Logistics Industry</h5>

            <p>
              Some of the greatest objectives of Shipkoo is lower inventory risk, responsiveness to
              customer demands and lower costs of pick and pack. Trade wars between China and the United
              States has resulted to anincrease in consolidation in the logistic industry. Picking and
              packaging each parcel is a labor cost, and wages are much cheaper at warehouses in Asia
              than in the US. The cost of renting spaces in the US is higher too. Therefore, instead of
              contracting many companies for storage, cartage, shipping, and ground transport, companies
              such as Shipkoo take over such responsibilities to reduce risk and improve oversight
              through origin fulfillment. Instead of shipping goods to the US, Shipkoo fulfills each
              order directly from China or other countries in Asia. Consolidation has been seen to
              increase, and it is expected to peak in the year 2020 and onwards.
            </p>
            <BottomShare>
              <div className="column-half">
                <p>Share the article</p>
              </div>
              <div className="column-half last">
                <FacebookShareButton url={href}>
                  <ShareButton><img src={FacebookDefaultIcon} alt="FacebookDefaultIcon" /> <span>Share</span></ShareButton>
                </FacebookShareButton>
                <TwitterShareButton url={href}>
                  <ShareButton><img src={TwitterDefaultIcon} alt="TwitterDefaultIcon" /> <span>Tweet</span></ShareButton>
                </TwitterShareButton>
                <LinkedinShareButton url={href}>
                  <ShareButton><img src={LinkedinDefaultIcon} alt="LinkedinDefaultIcon" /> <span>Share</span></ShareButton>
                </LinkedinShareButton>
              </div>
              <Clear></Clear>
            </BottomShare> 
          </div>
          <Clear></Clear>  
        </BlogRow>  
      </BlogPost>
      <SectionFull>
        <BlogRow>
          <h2>More From Shipkoo</h2>
          <PostsWrapper>
            {blogPosts.map(post => (
              <BlogBaseCard key={`${post.title}-${post.id}`} post={post} />
            ))}
          </PostsWrapper>
        </BlogRow>
      </SectionFull>
      <BlogOptin />
    </Layout>
  );
};

export default BlogArticle;

